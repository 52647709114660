import React from "react";
import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { XCircleIcon } from "@heroicons/react/20/solid";

/**
 * This component is used to display errors in the browser.
 * It is used by the ErrorBoundary component in app/root.tsx and route pages.
 *
 * In order to properly render route errors,
 * you must catch the error in your route and then throw the response found in the error.
 *
 * ## Example
 * ```
 * await someNetworkOperation().catch(async (err) => {
 *     if (err.response) {
 *       // this is the important part
 *       throw err.response;
 *     }
 *     // if there is no response, throw the error as a fallback
 *     throw err;
 *   });
 * ```
 * @constructor
 */
function ErrorMessage({ error: errorProp }: { error?: any }) {
  let error = useRouteError();
  if (!error && errorProp) {
    error = errorProp;
  }

  // response errors
  if (isRouteErrorResponse(error)) {
    if (error.status == 404) {
      return <CenteredError title="Not Found" message={error.data} />;
    } else if (error.status == 401) {
      return <CenteredError title="Unauthorized" message={error.data} />;
    } else if (error.status == 403) {
      return <CenteredError title="Forbidden" message={error.data} />;
    } else if (error.status > 400 && error.status < 500) {
      return <CenteredError title="Bad Request" message={error.data} />;
    } else if (error.status >= 500) {
      return <CenteredError title="Server Error" message={error.data} />;
    }

    if (error.status >= 500) {
      captureRemixErrorBoundaryError(error);
    }

    // other response errors
    return (
      <CenteredError title="Oops" status={error.status} message={error.data} />
    );
  }

  // other errors
  captureRemixErrorBoundaryError(error);
  let errorMessage = "Unknown error";
  if (isError(error)) {
    errorMessage = error.message;
  }
  return <CenteredError title="Oops" message={errorMessage} />;
}

/**
 * Renders the error message.
 * @param title
 * @param status
 * @param message If this is an object, it will be parsed to extract the error message or field error messages.
 * @constructor
 */
function CenteredError({
  title,
  status,
  message,
}: {
  title: string;
  status?: number;
  message: string | object;
}) {
  const errors = extractErrors({ data: message });

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              {status != null && <li>Status: {status}</li>}
              {errors.message != null && <li>{errors.message}</li>}
            </ul>
            <FieldErrorTable fields={errors.fields} />
          </div>
        </div>
      </div>
    </div>
  );
}

type Errors = {
  message?: string;
  fields: { [key: string]: string };
};

function extractErrors({ data }: { data: string | object }): Errors {
  const errors: Errors = { fields: {} };
  if (typeof data === "string") {
    errors.message = data;
  } else {
    if ("message" in data && data.message != null && data.message != "") {
      errors.message = data.message as string;
    }
    if ("fields" in data && data.fields instanceof Object) {
      for (const [key, value] of Object.entries(data.fields)) {
        errors.fields[key] = value;
      }
    }
  }
  return errors;
}

function FieldErrorTable({ fields }: { fields: { [key: string]: string } }) {
  if (Object.keys(fields).length == 0) {
    return null;
  }
  return (
    <div>
      <p className="py-5">Here are some specific problems with your request:</p>
      <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Parameter Errors
              </h3>
            </div>
          </div>
        </div>
        <ul className="divide-y divide-gray-100">
          {Object.entries(fields).map(([key, value]) => (
            <li
              key={key}
              className="flex justify-between gap-x-6 px-4 py-4 sm:px-6"
            >
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {key}
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    {value}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function isError(error: any): error is Error {
  return error != null && typeof error.message === "string";
}

export default ErrorMessage;
